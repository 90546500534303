<template>
  <span v-if="user != null">
    <v-tooltip bottom :key="refresh">
      <template v-slot:activator="{ on, attrs }" class="">
        <span>
          <v-badge v-if="isOnline" bordered bottom :color="cor.active.V" dot offset-x="6" offset-y="6" :key="refresh">
            <v-avatar :size="size" v-bind="attrs" v-on="on">
              <img :src="user.urlFoto" alt="" :key="refresh">
            </v-avatar>
          </v-badge>
          <v-badge v-if="!isOnline" bordered bottom :color="cor.active.b" dot offset-x="6" offset-y="6" :key="refresh">
            <v-avatar :size="size" v-bind="attrs" v-on="on">
              <img :src="user.urlFoto" alt="" :key="refresh">
            </v-avatar>
          </v-badge>
        </span>
        <span class="ms-1 f-raleway fw-500 fs-7pt">{{user.nome}}</span>
      </template>
      <p class="p-0 m-0 mt-0 f-raleway fw-500 fs-8pt">{{user.nome}}</p>
    </v-tooltip>
  </span>
</template>

<script>
import { rdb } from '@/firebasedb.js';
import moment from "moment";

export default {
  name: "avataronline",
  components: {},
  props: {
    userID: { default: null, type: String},
    size: { size: 34, type: Number},
  },
  computed: {
    isOnline() {
      return this.online && moment().diff(this.lastPingServer, 'seconds') < 40;
    },
    diff() {
      return moment().diff(this.lastPingServer, 'seconds');
    }
  },
  data(){
    return {
      loading: false,
      refresh: 0,
      user: null,
      lastPing: moment().format(), //moment().subtract(5, 'minutes').format(),
      lastPingServer: moment().subtract(5, 'minutes').format(),
      interval: null,
      online: false,
    }
  },
  watch: {
    userID() {
      this.build();
    }
  },
  created: function() {
    var self = this;

  },
  mounted: function() {
    var self = this;
    self.build();
  },
  methods:{

    build() {
      var self = this;

      if(self.userID != null) {
        rdb.ref('/usuarios/pessoas/'+self.userID).on('value',function(snapshot) {
          self.user = snapshot.val();
          self.refresh++;
          self.$forceUpdate();
        });
        rdb.ref('/usuarios/pessoasLogControl/'+self.userID).on('value',function(snapshot) {
          var last = moment(self.lastPingServer);
          var obj = snapshot.val();
          if(obj != null) {
            self.online = obj.logged;
            self.lastPingServer = obj.last;
            //console.log("self.lastPing",self.lastPingServer);
            //console.log("diff",moment().diff(last, 'seconds'));
            self.refresh++;
            self.$forceUpdate();
          }
        });

      }
    }
  }
}
</script>

<style scoped>
</style>
